body {
  font-family: 'Nanum Square', sans-serif !important;
  font-size: 14px ;

}

._click {
  cursor: pointer !important;
}
div{ -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

/*.joinForm input{*/
/*  !*border:0;*!*/
/*  !*border-radius: 0px;*!*/
/*  !*border-bottom: 1px solid gray;*!*/
/*}*/
.form-control{
  /*font-family: 'Roboto';*/
  font-weight: bold !important;
  font-size: 20px !important;
}
._docu td{
  padding-top: 10px;
}
.largee .list-group-item{
  background-color: white !important;
  font-size: 22px !important;
}
._under {
  border-radius: 0px;
  border:0px !important;
}
/*.me-auto {*/
/*  !*margin-top: 20px;*!*/
/*  font-size: 20px;*/
/*  color: gray;*/
/*}*/

.list-group-item {
  font-size: 16px;
  border: 0px !important;
  padding: 0 !important;
  margin-bottom: 20px;
  background-color: #f5f5f5 !important
}

a.dropdown-item:hover{
  background-color: rgba(0,0,0,0.2);
}

.clamp3 {

  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

